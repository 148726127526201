import {
  type TimelineCountEvent,
  type TimelineEvent,
  type TimelineInterval,
  type TimelineRangeEvent,
} from "@evercam/ui"
import moment from "moment-timezone"
import {
  type TimelineDateInterval,
  TimelinePrecision,
  type TimelineProviderRequestParams,
} from "@evercam/shared/types"

export abstract class TimelineDataProvider {
  constructor(protected timezone: string) {}

  public async fetch(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineEvent>> {
    if (params.precision === TimelinePrecision.Events) {
      return this.fetchEvents(params)
    } else {
      return this.fetchCounts(params)
    }
  }

  protected abstract fetchEvents(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineEvent>>

  protected abstract fetchCounts(
    params: TimelineProviderRequestParams
  ): Promise<Array<TimelineCountEvent | TimelineRangeEvent>>

  formatTimestamp(date: string, format: string = ""): string {
    return moment.tz(date, this.timezone).format(format)
  }

  processResponseIntervals(
    intervals: TimelineDateInterval[],
    precision: TimelinePrecision
  ): TimelineRangeEvent[] {
    const roundedIntervals = this.convertAndRoundIntervals(intervals, precision)

    if (precision === TimelinePrecision.Events) {
      return roundedIntervals as TimelineRangeEvent[]
    }

    return this.groupAdjacentIntervals(
      roundedIntervals,
      precision
    ) as TimelineRangeEvent[]
  }

  private convertAndRoundIntervals(
    intervals: TimelineDateInterval[],
    precision: TimelinePrecision
  ): TimelineInterval[] {
    return intervals.map(({ fromDate, toDate }) => {
      let startDate = this.formatTimestamp(fromDate)
      let endDate = this.formatTimestamp(toDate)

      if (startDate !== endDate) {
        return {
          startDate,
          endDate,
        }
      }

      if (precision === TimelinePrecision.Events) {
        startDate = moment(startDate).add(1, "seconds").format()
        endDate = moment(endDate).add(6, "seconds").format()
      } else {
        startDate = moment(startDate)
          .startOf(precision as moment.unitOfTime.Base)
          .format()
        endDate = moment(endDate)
          .endOf(precision as moment.unitOfTime.Base)
          .format()
      }

      return {
        startDate,
        endDate,
      }
    })
  }

  private groupAdjacentIntervals(
    intervals: TimelineInterval[],
    precision: TimelinePrecision
  ): TimelineInterval[] {
    return intervals
      .sort((a, b) => {
        return a.startDate > b.startDate ? 1 : -1
      })
      .reduce((acc, interval) => {
        if (!acc.length) {
          return [interval]
        }

        const [previousInterval] = acc.slice(-1)
        const diff = Math.abs(
          moment(previousInterval.endDate).diff(interval.startDate)
        )
        const minimumDuration = moment
          .duration(1, precision as moment.unitOfTime.Base)
          .asMilliseconds()

        if (diff < minimumDuration) {
          return [
            ...acc.slice(0, acc.length - 1),
            {
              startDate: previousInterval.startDate,
              endDate: interval.endDate,
            },
          ]
        } else {
          return [...acc, interval]
        }
      }, [] as TimelineInterval[])
  }
}
